/* You can add global styles to this file, and also import other style files */
@import './../src/assets/css/app.css';
.angular-editor-button{
  background: #354055 !important;
}


.angular-editor-toolbar{
  background:#354055 !important;
}


.body-article{
  background-color: white;
  p {
    font-size: 1.1rem  !important;
    line-height: 1.8 !important;
}
}
.border-white{
  border: 1px solid rgba(255, 255, 255, 0.497) !important
}

.color-black{
  color: #101010 !important;
}
.color-yellow{
  color: #F9E547 !important;
}
.bebas-neue-bold {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  font-style: normal;
}


.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}



.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.oswald-regular{
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.oswald-bold{
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


.caveat-regular {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}




.headline{
  font-size: 120px;
  @extend .bebas-neue-bold;
  line-height: 100px;

  @media screen and (max-width: 39.9375em) {
    font-size: 70px;
 
    line-height: 70px;
  }
  
}


.section-title{
  font-size: 40px;
  line-height: 50px;
  @extend .bebas-neue-bold;

  @media screen and (max-width: 39.9375em) {
    font-size: 28px;
    line-height: 30px;
  }
}

.section-subtitle{
  font-size: 26px;
  line-height: 36px;
  @extend .oswald-regular;
 
  @media screen and (max-width: 39.9375em) {
    font-size: 20px;
    line-height: 30px;
  }
}

.section-desc{
  font-size: 24px;
  line-height: 36px;

  @extend .lato-regular;
  @media screen and (max-width: 39.9375em) {
    font-size: 20px;
    line-height: 30px;
  }
}


.cursive-title{
  font-size: 40px;
  line-height: 50px;
  @extend .caveat-regular;

  @media screen and (max-width: 39.9375em) {
    font-size: 24px;
    line-height: 30px;
  }
}

.cursive-subtitle{
  font-size: 40px;
  line-height: 50px;
  @extend .caveat-regular;

  @media screen and (max-width: 39.9375em) {
    font-size: 24px;
    line-height: 30px;
  }
}


.section-para{
  font-size: 22px;
  line-height: 34px;
  @extend .bebas-neue-bold;
  @extend .lato-regular;
  @media screen and (max-width: 39.9375em) {
    font-size: 16px;
    line-height: 22px;
  }
}





.main-button{
  font-size: 16px !important;
  padding: 2px;
  border-radius: unset !important;
  background: transparent !important;
  border: 1px solid #F9E547 !important;
  text-transform: uppercase;
  &.clean{
    border: unset !important;
    span{
      background: unset !important;
      color: white !important;
      @extend .oswald-regular;
    }
  
  }
  @extend .color-black;
  &:hover{
    background: #F9E547 !important;
    &.clean{
      background: unset !important;
    }
  }
  span{
    background: #F9E547 !important;
    padding: 8px 25px;
    display: block;
    
    @extend .oswald-regular;
  }
 
}


.activity-tile{
  .title{
    font-size: 20px;
    @extend .oswald-regular
  }
  .subtitle{
    font-size: 14px ;
    @extend .lato-regular
  }
}


.accordion-container{
  .title{
    font-size: 22px;
    color: white;
    @extend .oswald-regular;
    border-bottom : 1px dashed white;
    padding: 1rem 0;
    cursor: pointer;
    @media screen and (max-width: 39.9375em) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .subtitle{
    font-size: 18px;
    @extend .section-para;
    @extend .lato-regular;
    color: white;
  }
  .activity-tile{
    .title{
      font-size: 22px;
      line-height: 20px;
      @extend .oswald-regular;
      border-bottom :  unset !important;
    padding:  unset !important;
    }
    .subtitle{
      font-size: 18px ;
      line-height: 24px;
      @extend .lato-regular
    }
  }
  

  
}









:root {
  color-scheme: dark;
}
input {
  color-scheme: dark;
}

.cursor {
  cursor: pointer;
}

.hover-show {
  &:hover {
    .hide {
      display: inline-block !important;
    }
  }
}

.more-button {
  position: absolute;
  right: 1%;
  top: 7%;
}

.like {
  color: brown !important;
}

.pinned {
  color: white !important;
}


.chat{
  animation: chat-pulse 1.5s infinite;
  @keyframes chat-pulse {
   0% {
     transform: scale(0.95);
     box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
   }
   
   70% {
     transform: scale(0.85);
     box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
   }
   
   100% {
     transform: scale(0.95);
     box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
   }
 }
}


.loading {
  border-radius: 6px;
  -webkit-animation: pulse 2s infinite;
  /* Safari 4+ */
  -moz-animation: pulse 2s infinite;
  /* Fx 5+ */
  -o-animation: pulse 2s infinite;
  /* Opera 12+ */
  animation: pulse 2s infinite;
  /* IE 10+, Fx 29+ */

  @keyframes pulse {
    0% {
      color: transparent;
      background-color: #969696;
      border: none;
    }

    50% {
      color: transparent;
      background-color: #b7b7b7;
      border: none;
    }

    100% {
      color: transparent;
      background-color: #969696;
      border: none;
    }
  }

}



.avatar-placeholder {
  border-radius: 100%;
  border: 1px solid rgb( 60, 65, 74 )!important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  background: rgb(60, 65, 74) !important;
  font-size: 10px;
  text-transform: uppercase;
  &:hover{
    border: 1px solid white !important;
  }
}


.avatar-image {
  border-radius: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  width: 50px;
  height: 50px;
 
}


.task-container {
  position: relative;
  min-height: 120px;

  .more-info {
    position: absolute;
    top: 15px;
    right: 15px
  }

  .more-info-bottom {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }

}

.add-person {
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border: 2px solid rgb(60, 65, 74) !important;
  border-radius: 100%;
  font-size: 10px;
  background: rgb(60, 65, 74) !important;

  .icon {
    -ms-transform: translateY(-5%);
    transform: translateY(-5%);
  }
}

a.link {
  color: rgb(240, 241, 243);

  &:hover {
    border-bottom: 2px solid rgb(240, 241, 243);
  }
}

.page-container {
  padding: 2% 3%;

}

.title-container {
  margin-bottom: 2rem;
}

//button

.button {
  &.clear {
    background: transparent !important;
    border: 1px solid transparent !important;
    border: none !important;
    background: #313d534f !important;

    &:hover {
      opacity: 0.8;
      color: rgb(240, 241, 243);


    }
  }


  &.active {

    border: 1px solid #26292f !important;
    background: #26292f !important;

  }

  &.hollow {

    border: 1px solid #5e5e5ea5 !important;
  }



}

.pagination-button {
  background: rgb(60, 65, 74) !important;
  color: rgb(240, 241, 243) !important;
  border: none;
  padding: 0.3rem 0.7rem !important;
  line-height: 1;
  font-size: 12px;
  margin: 0;
}

.width-25 {
  width: 15%;
  float: left;
  display: inline-block;
}

.width-75 {
  width: 85%;
  float: right;
  display: inline-block;
}

.width-100 {
  width: 100%;
  float: left;
  display: inline-block;
}

.page-from-left {
  animation: show-from-left 3s;
}

@keyframes page-from-left {
  0% {
    background: red;
  }

  100% {
    background: yellow;
  }
}

.test-media {
  display: none;
}




.flex-item {
  flex-wrap: nowrap;
}

.color-grey {
  color: rgb(135, 144, 158) !important;
}


body,
.main-body {
  background: #354055;
  @extend .lato-regular;
  
  color: rgb(240, 241, 243) !important
}




.list-tile-container {



  .list-tile-item {
    border-bottom: 1px solid #475264 !important;
    padding: 0.7rem 0;
    font-size: 14px;


  }

  .list-tile-item:last-child {
    border-bottom: none;

  }
}

.form {

  .input,
  input,
  select,
  
  textarea {

    
    font-size: 18px !important;
    border-radius: 0 !important;
    padding: .5rem 0rem !important;
    line-height: 1.7;
    color: white !important;
    // background: #272829 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.45) !important;
    background: transparent !important;
   
  }

  .text{
    font-size: 18px !important;
  }
  
}

.table-action {
  padding: 0.5rem 0;

  input {
    margin: 0
  }
}

.table-container {
  .bar{
    width: 40px;background: black;position: absolute;right:0;top:0;height: 100%;z-index: 99;   background: rgb(48,53,60);
                    background: linear-gradient(270deg, rgb(41, 45, 51) 0%, rgba(48,53,60,0) 100%);
  }
  .parrent{
    width: 100%;
    overflow: auto;
  }
 .child{
  min-width: max-content;
 }
}

.table-header {

  top: 0;
  z-index: 99;
  .table-header-item {
    padding: 1rem 1rem;
    font-size: 12px;


    color: rgb(135, 144, 158) !important;

    .flex-item {
      margin-right: 0.7rem;

    }

    &:hover {
      transition: all .3s ease;
    }
  }
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.table-body {


  .table-body-item {
    padding: 0.7rem 1rem;
    font-size: 13px;
    overflow-wrap: break-word;
    background: #313d53d9;

    border-radius: 6px;
    margin-bottom: 2px;

    &.border {
      border-top: 1px solid rgb(60, 65, 74);
      background: unset;
      border-radius: unset !important;

      &:hover {
        transition: all .7s ease;
        // background: rgba(36, 36, 36, 0.66);
        // border: 1px solid rgb(240, 241, 243);
      }

      // &.border:last-child {
      // border: 1px solid rgb( 60, 65, 74 );

      // }
    }

    .flex-item {
      margin-right: 0.7rem;

    }

    &:hover {
      transition: all .1s ease;
      background: #313d53ed;


    }
  }

  .table-body-item:last-child {
    border-bottom: none;

  }
}


.padding-bottom-05 {
  padding-bottom: 0.5rem;
}



::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(226, 226, 226, 0.397)
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(226, 226, 226, 0.397)
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(226, 226, 226, 0.397)
}

.content-wrapper {
  padding: 0 3%
}

.border-bottom {
  border-bottom: 1px solid #475264 !important;
}

.border-top {
  border-top: 1px solid rgb(60, 65, 74) !important;
}


.mat-progress-bar-buffer {
  background-color: #101010 !important;
}


.callout.padding-mini-center {
  padding: 1rem;
}



.gray-color {
  color: rgb(240, 241, 243) !important
}

.medium-grey-bg {
  background-color: #313d53 !important;
  
  color: rgb(240, 241, 243) !important
}

.dark-grey-bg {
  background: rgb(42, 46, 52) !important;
  color: rgb(240, 241, 243) !important
}


.warning-bg {
  border: 1px solid #ff971f !important;

  background-color: #ff971f3d !important;
  color: rgb(240, 241, 243) !important
}




.chips {


  border-radius: 25px;


  display: block;

  &.active {
    background: rgb(240, 241, 243) !important;
    color: #333333 !important;
  }
}

.fade-in {
  animation: fadeIn 0.3s;
}

.long-fade-in {
  animation: fadeIn 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.zoom-in {
  animation: zoom 0.2s;
}

@keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}


.page-enter-from-left {
  animation: page-enter-from-left 0.3s;
}

@keyframes page-enter-from-left {
  0% {
    transform: translateX(-50%);
    opacity: 0.4;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.page-enter-from-right {
  animation: page-enter-from-right .3s;
}

@keyframes page-enter-from-right {
  0% {
    transform: translateX(50%);
    opacity: 0.4;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.page-enter-from-bottom {
  animation: page-enter-from-bottom .2s;
}

@keyframes page-enter-from-bottom {
  0% {
    transform: translateY(70%);
    opacity: 0.7;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.page_out_to_right {
  animation: page-out-to-right .3s;
}

@keyframes page-out-to-right {
  0% {
    transform: translateX(0%);

  }

  100% {
    transform: translateX(100%);

  }
}


//
.right-bar-container {
  .right-bar-content {
    padding: 2rem;
    position: relative;

  }
}

.span-label {
  border: 1px solid rgb( 64, 166, 230 );
  // background: rgb( 42, 46, 52 ) !important;
  background: rgb(64 166 230 / 22%) !important;

  font-size: 8px;
  padding: 1px 7px;
  font-weight: 600;
  text-transform: uppercase;
vertical-align: middle;
  border-radius: 3px;
  letter-spacing: 1px;




}



.show-from-left {
  animation: show-from-left 0.5s;
}

@keyframes show-from-left {
  0% {
    right: -25%;
    z-index: 9999999999;
  }

  100% {
    right: 0
  }
}



.mega-text {
  font-size: 25px !important;

  input {
    font-size: 25px !important;
  }
}



.jumbo-text {
  font-size: 20px !important;

  input {
    font-size: 20px !important;
  }
}


.huge-text {
  font-size: 16px !important;

  input {
    font-size: 16px !important;
  }
}


.large-text {
  font-size: 15px !important;

  input {
    font-size: 14px !important;
  }
}

.small-text {
  font-size: 12px !important
}

.tiny-text {
  font-size: 10px !important
}



/* IPHONE */
@media screen and (max-width: 39.9375em) {
  .page-container {
    padding: 1rem !important;

  }
  .padding-center {
    padding: 2rem 0 !important;
}

  .small-100 {
    flex: 0 0 100% !important;
  }

  .hide-for-mobile {
    display: none !important
  }

  .width-25 {
    width: 100%;
    float: left;
    display: inline-block;
  }

  .width-75 {
    width: 100%;
    float: left;
    display: inline-block;
  }


  h1{
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  h2{
    font-size: 18px  !important;
    margin-bottom: 0 !important;
  }
  h3{
    font-size: 16px  !important;
    margin-bottom: 0 !important;
  }


}




/* IPAD */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .hide-on-tablet {
    display: none !important;
  }

  .test-media {
    display: block;
  }

  h1 {
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: 18px !important;
    margin-bottom: 0 !important;
  }

  h3 {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }





  // .callout.padding-center{
  //   padding: 1.5rem;
  // }
  // .table-header .table-header-item{
  //   font-size: 12px;
  // }
  // .table-body .table-body-item {
  //   font-size: 12px;
  // }
  // .large-text{
  //   font-size: 12px !important;
  // }
  // .small-text{
  //   font-size: 10px !important;
  // }

  // .tiny-text{
  //   font-size: 8px !important;
  // }







}